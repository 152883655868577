<script>
  import { defineComponent } from "@vue/composition-api";

  export default defineComponent({
    setup() {}
  });
</script>
<template>
  <h3>This is where you can Add a Contract</h3>
</template>
